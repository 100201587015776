.login-container {
    background-image: url('../../assets/images/login_bg.png');
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-card-container {
    border-radius: 15px;
    padding: 10px;
    min-height: 50vh;
}

.img-login-card {
    width: 150px;
    padding: 2%;
}