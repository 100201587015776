.searchField .MuiInputBase-root {
  border-radius: 5px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  height: 60px !important;
}

.searchField .MuiInputBase-input {
  padding-left: 6px !important;
  color: #000000 !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  min-width: 20rem !important;
}