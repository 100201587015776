.user-textbox .MuiInputBase-root {
  color: #201f1fcc !important;
}

.user-label-name {
  margin-bottom: 0.4rem !important;
  color: #201f1fcc !important;
}

.user-creation-container {
  border-radius: 15px;
  padding: 0.5rem 4rem 1rem 5rem;
}

.user-creation-content  .MuiBox-root {
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
}

.user-creation-content {
  padding: 2rem;
  padding-left: 8rem;
  border: 1.8px dotted #004980;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.button-container {
  float: right;
  padding: 0 1rem 2rem 0;
}