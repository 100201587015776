.organization-creation-content{
  padding: 2rem 14rem 2rem 10rem;
  border: 1.8px dotted hsl(206, 100%, 25%);
  border-radius: 10px;
  margin-bottom: 1rem;
}

.organization-creation-container {
  border-radius: 15px;
  padding:0.4rem 14rem 1rem 14rem;
}

.organization-creation-content  .MuiBox-root {
  margin-top: 0 !important;
  margin-bottom: 0rem !important;
}

.org-textbox .MuiInputBase-root {
  color: #201f1fcc !important;
}

.org-label-name {
  margin-bottom: 0.4rem !important;
  color: #201f1fcc !important;
}

.button-container {
  float: right;
  padding: 0 1rem 2rem 0;
}