.sidebar-container{
    border: 1px solid #000;
    border-radius: 50px 50px 0 0;
    background-color: #F8F8F8;
    min-height: 100vh;
}

.sidebar-wrapper{
    background-color: white;
    border-radius: 50px 0 0 0;
    padding: 1%;
    max-width: 100px !important;
}

.content-wrapper{
    border-radius: 0 50px 0 0;
    padding: 0.5%;
}

.content-main{
    padding: 0.5%;
    width: 100%;
    border-radius: 10px;
}

.link {
    display: flex;
    color: #004980;
    padding: 10px;
    font-weight: 500;
    text-decoration: none;
    margin: 3px;
    margin-bottom: 8px;
    justify-content: center;
    width: 50px;
}

.link:hover {
    background-color: #004980;
    color: #ffffff;
    border-radius: 5px;
    transition: 0.5s;
}

.active {
    background-color: #004980;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 500;
}

.menu_section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    margin-left: 5px;
}

.link_text {
    font-weight: 500;
    padding-left: 10px;
}

@media only screen and (max-width: 1000px) {
    .link_text {
        display: none !important;
    }
    .link{
        padding: 0px;
        gap: 5px;
        margin: 0;
        
    }
  }